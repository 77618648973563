import React, {
  useEffect,
  Fragment,
  useCallback,
  useState,
  useMemo,
} from "react";
import { connect } from "react-redux";
import Intercom from "react-intercom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import lodash from "lodash";
import Unauthorized from "./components/Unauthorized/Unauthorized";
import Login from "./pages/Auth/Login/Login";
import SignUp from "./pages/Auth/Signup/Signup";
import ForgotPassword from "./pages/Auth/ForgotPassword/ForgotPassword";
import Dashboard from "./pages/Dashboard/Dashboard";
import PrivateRoute from "./PrivateRoute";
import ResetPassword from "./pages/Auth/ResetPassword/ResetPassword";
import AccountActivation from "./pages/AccountActivation/AccountActivation";
import Settings from "./pages/Settings/Settings";
import Billing from "./pages/Billing/Billing";
import Tools from "./pages/Tools/Tools";
import Logs from "./pages/Logs/Logs";
import services from "./services";
import {
  clearIntercomAppIdStatus,
  userLoginSuccess,
} from "./store/actions/userActions";
import Snackbar from "./components/Snackbar/Snackbar";
import LogDetails from "./components/LogsComponents/LogDetails/LogDetails";
import LogStatistics from "./components/LogsComponents/LogStatistics/LogStatistics";
import LogsSearchStepOne from "./components/LogsComponents/LogsSearchStepOne/LogsSearchStepOne";
import LogSearchStepTwo from "./components/LogsComponents/LogSearchStepTwo/LogSearchStepTwo";
import Integrations from "./pages/Integrations/Integrations";
import SubscriptionPlans from "./components/SubscriptionPlans/SubscriptionPlan";
import PaymentForm from "./components/BillingPageComponents/PaymentComponents/PaymentForm";
import ChangeSubscriptionPlan from "./components/BillingPageComponents/PaymentComponents/ChangeSubcriptionPlan";
import IgnoredEmails from "./components/ToolsPageComponents/IgnoredEmails/IgnoredEmails";
import PrimaryContacts from "./components/ToolsPageComponents/PrimryContacts/PrimaryContacts";
import CleanupUnsyncPair from "./components/ToolsPageComponents/CleanupUnsyncPair/CleanupUnsyncPair";
import HeaderMapping from "./components/ToolsPageComponents/HeaderMapping/HeaderMapping";
import Invoices from "./components/BillingPageComponents/Invoices/Invoices";
import {
  formDataWithApiKey,
  getUrlParameterByName,
  transferToLegacy,
} from "./helpers";
import { getStripePublishableKey } from "./store/middlewares/billingMiddleware";
import Services from "./pages/Services/Services";
import Users from "./pages/Admin/Users/Users";
import Loader from "./components/Loader/Loader";
import SetupCcbAccount from "./components/ServicesComponents/SetupCcbAccount/SetupCcbAccount";
import SetupMailchimpAccount from "./components/ServicesComponents/SetupMailChimpAccount/SetupMailchimpAccount";
import UnresolveConflictsModal from "./components/modals/UnresolveConflictsModal/UnresolveConflictsModal";
import InvoiceDetails from "./components/BillingPageComponents/InvoiceDetails/InvoiceDetails";
import ResolveBrokenPairsModal from "./components/modals/ResolveBrokenPairsModal/ResolveBrokenPairsModal";
import { getIntercomAppId } from "./store/middlewares/userMiddleware";
import Notifications from "./pages/Notifications/Notifications";
import PendingUsers from "./pages/Admin/PendingUsers/PendingUsers";

const {
  storageManager: { local },
  api,
} = services;
const App = (props) => {
  const {
    // Redux props
    showSnackbar,
    stripePublishableKey,
    userDataFromRedux,
    warnings,
    intercomAppIdStatus,
    intercomAppId,

    // Redux functions
    setUserData,
    getStripePublishableKey,
    getIntercomAppId,
    clearIntercomAppIdStatus,
  } = props;
  const [loading, setLoading] = useState(false);
  const userData = JSON.parse(local.get("user"));

  const loadStripe = useCallback(() => {
    if (!window.document.getElementById("stripe-script")) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        window["Stripe"].setPublishableKey(stripePublishableKey);
      };
      window.document.body.appendChild(s);
    }
  }, [stripePublishableKey]);

  const setUserDataInRedux = useCallback(
    (data) => {
      local.set("user", JSON.stringify(data));
      setUserData(data);
    },
    [setUserData]
  );

  const setCurrentUserDetails = useCallback(async () => {
    setLoading(true);
    const formData = formDataWithApiKey();
    const response = await api.getUserDetails(formData);
    setLoading(false);
    const savedUserData = JSON.parse(local.get("user"));
    if (response.status === 1) {
      setUserDataInRedux(response.data);
    } else {
      setUserData(savedUserData);
    }
  }, [setUserData, setUserDataInRedux]);

  const setAdminDataInLocalStorage = useCallback(async (adminApiKey) => {
    const formData = new FormData();
    formData.append("apiKey", adminApiKey);
    const response = await api.getUserDetails(formData);
    if (response.status === 1) {
      local.set("admin", JSON.stringify(response.data));
      window.Intercom("shutdown");
    }
  }, []);

  const setUserDataInLocalStorage = useCallback(
    async (apiKeyFromParam) => {
      const formData = new FormData();
      const adminApiKey = getUrlParameterByName("adminUserId");
      const isAdminParam = getUrlParameterByName("isAdmin");
      const admminData = JSON.parse(local.get("admin"));
      formData.append("apiKey", apiKeyFromParam);
      const response = await api.getUserDetails(formData);
      if (response.status === 1) {
        local.set("user", JSON.stringify(response.data));
        local.set("token", response.data.apiKey);
        setUserData(response.data);
        if (
          Number(isAdminParam) === 1 &&
          adminApiKey &&
          adminApiKey !== apiKeyFromParam
        ) {
          if (lodash.isEmpty(admminData)) {
            setAdminDataInLocalStorage(adminApiKey);
          } else {
            local.remove("admin");
            setAdminDataInLocalStorage(adminApiKey);
          }
        }
      }
    },
    [setAdminDataInLocalStorage, setUserData]
  );

  const loginUserWhenTransferFromV1 = useCallback(() => {
    const apiKeyParam = getUrlParameterByName("apiKey");
    if (apiKeyParam) {
      if (lodash.isEmpty(userData)) {
        setUserDataInLocalStorage(apiKeyParam);
      } else if (!lodash.isEmpty(userData)) {
        local.remove("user");
        local.remove("token");
        setUserDataInLocalStorage(apiKeyParam);
      }
    }
  }, [setUserDataInLocalStorage, userData]);

  useEffect(() => {
    if (intercomAppIdStatus == null) {
      getIntercomAppId();
    } else if (intercomAppIdStatus === "success") {
      clearIntercomAppIdStatus();
    }
  }, [clearIntercomAppIdStatus, intercomAppIdStatus, getIntercomAppId]);

  useEffect(() => {
    const adminData = JSON.parse(local.get("admin"));
    if (
      !lodash.isEmpty(userDataFromRedux) &&
      userDataFromRedux.appVersion === 1 &&
      !adminData
    ) {
      transferToLegacy(userDataFromRedux);
    }
    loginUserWhenTransferFromV1();
  }, [loginUserWhenTransferFromV1, userDataFromRedux]);

  useEffect(() => {
    if (stripePublishableKey) {
      loadStripe();
    }
  }, [loadStripe, stripePublishableKey]);

  useEffect(() => {
    // GET STRIPE PUBLISHABLE KEY
    const formData = formDataWithApiKey();
    getStripePublishableKey(formData);
  }, [getStripePublishableKey]);

  useEffect(() => {
    //set user data in redux state from localstorage if it's empty
    const savedUserData = JSON.parse(local.get("user"));
    if (lodash.isEmpty(props.userData) && !lodash.isEmpty(savedUserData)) {
      setCurrentUserDetails();
    }
  }, [props.userData, setCurrentUserDetails]);

  const renderIntercom = useMemo(() => {
    const isAdminParam = getUrlParameterByName("isAdmin");
    const adminData = JSON.parse(local.get("admin"));
    const adminApiKey = getUrlParameterByName("adminUserId");
    if (
      !adminData &&
      Number(isAdminParam) === 0 &&
      !adminApiKey &&
      intercomAppId &&
      warnings?.intercom_user_hash
    ) {
      return (
        <Intercom
          appID={intercomAppId}
          user_id={userData?.id || 0}
          email={userData?.email || ""}
          name={userData?.firstName || ""}
          user_hash={warnings?.intercom_user_hash || ""}
          api_base={"https://api-iam.intercom.io"}
        />
      );
    }
  }, [userData, warnings, intercomAppId]);

  return (
    <Fragment>
      {renderIntercom}
      <BrowserRouter>
        <Switch>
          {/* Public routes */}
          <Route exact path="/" component={Login} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password/:key" component={ResetPassword} />
          {/* Private route start here*/}
          {/* User routes */}
          <PrivateRoute
            exact
            path="/dashboard"
            component={Dashboard}
            hidePageMenu={true}
            isLoggedIn={[1, 2].includes(userData?.role) && userData?.apiKey}
          />
          <PrivateRoute
            exact
            path="/activate-account/:key"
            component={AccountActivation}
            hidePageMenu={true}
            isLoggedIn={[1, 2].includes(userData?.role)}
          />
          <PrivateRoute
            exact
            path="/sync"
            component={Integrations}
            hidePageMenu={true}
            isLoggedIn={
              [1, 2].includes(userData?.role) &&
              userData?.isActivated &&
              userData?.apiKey
            }
          />
          <PrivateRoute
            exact
            path="/sync/resolve-broken-pairs"
            component={ResolveBrokenPairsModal}
            hidePageMenu={true}
            isLoggedIn={
              [1, 2].includes(userData?.role) &&
              userData?.isActivated &&
              userData?.apiKey
            }
          />
          <PrivateRoute
            exact
            path="/settings"
            component={Settings}
            hidePageMenu={false}
            isLoggedIn={
              [1, 2].includes(userData?.role) &&
              userData?.isActivated &&
              userData?.apiKey
            }
          />
          <PrivateRoute
            exact
            path="/billing"
            component={Billing}
            hidePageMenu={false}
            isLoggedIn={
              [1, 2].includes(userData?.role) &&
              userData?.isActivated &&
              userData?.apiKey
            }
          />

          <PrivateRoute
            exact
            path="/billing/invoices"
            component={Invoices}
            hidePageMenu={false}
            isLoggedIn={
              [1, 2].includes(userData?.role) &&
              userData?.isActivated &&
              userData?.apiKey
            }
          />
          <PrivateRoute
            exact
            path="/billing/invoices/details/:invoiceId"
            component={InvoiceDetails}
            hidePageMenu={false}
            isLoggedIn={
              [1, 2].includes(userData?.role) &&
              userData?.isActivated &&
              userData?.apiKey
            }
          />
          <PrivateRoute
            exact
            path="/notifications"
            component={Notifications}
            hidePageMenu={false}
            isLoggedIn={
              [1, 2].includes(userData?.role) &&
              userData?.isActivated &&
              userData?.apiKey
            }
          />
          <PrivateRoute
            exact
            path="/tools"
            component={Tools}
            hidePageMenu={true}
            isLoggedIn={
              [1, 2].includes(userData?.role) &&
              userData?.isActivated &&
              userData?.apiKey
            }
          />

          <PrivateRoute
            exact
            path="/tools/ignored-emails"
            component={IgnoredEmails}
            hidePageMenu={true}
            isLoggedIn={
              [1, 2].includes(userData?.role) &&
              userData?.isActivated &&
              userData?.apiKey
            }
          />

          <PrivateRoute
            exact
            path="/tools/primary-contacts"
            component={PrimaryContacts}
            hidePageMenu={true}
            isLoggedIn={
              [1, 2].includes(userData?.role) &&
              userData?.isActivated &&
              userData?.apiKey
            }
          />

          <PrivateRoute
            exact
            path="/tools/confirm-delete"
            component={CleanupUnsyncPair}
            hidePageMenu={true}
            isLoggedIn={
              [1, 2].includes(userData?.role) &&
              userData?.isActivated &&
              userData?.apiKey
            }
          />

          <PrivateRoute
            exact
            path="/logs"
            component={Logs}
            hidePageMenu={true}
            isLoggedIn={
              [1, 2].includes(userData?.role) &&
              userData?.isActivated &&
              userData?.apiKey
            }
          />
          <PrivateRoute
            exact
            path="/logs/details/:logId"
            component={LogDetails}
            hidePageMenu={true}
            isLoggedIn={
              [1, 2].includes(userData?.role) &&
              userData?.isActivated &&
              userData?.apiKey
            }
          />
          <PrivateRoute
            exact
            path="/logs/details/:logId/statistics/:detailId"
            component={LogStatistics}
            hidePageMenu={true}
            isLoggedIn={
              [1, 2].includes(userData?.role) &&
              userData?.isActivated &&
              userData?.apiKey
            }
          />

          <PrivateRoute
            exact
            path="/logs/search-results"
            component={LogsSearchStepOne}
            hidePageMenu={true}
            isLoggedIn={
              [1, 2].includes(userData?.role) &&
              userData?.isActivated &&
              userData?.apiKey
            }
          />

          <PrivateRoute
            exact
            path="/logs/search-results/:type/:query"
            component={LogSearchStepTwo}
            hidePageMenu={true}
            isLoggedIn={
              [1, 2].includes(userData?.role) &&
              userData?.isActivated &&
              userData?.apiKey
            }
          />

          <PrivateRoute
            exact
            path="/integrations"
            component={Services}
            hidePageMenu={true}
            isLoggedIn={
              [1, 2].includes(userData?.role) &&
              userData?.isActivated &&
              userData?.apiKey
            }
          />

          <PrivateRoute
            exact
            path="/integrations/setup-ccb"
            component={SetupCcbAccount}
            hidePageMenu={true}
            isLoggedIn={
              [1, 2].includes(userData?.role) &&
              userData?.isActivated &&
              userData?.apiKey
            }
          />

          <PrivateRoute
            exact
            path="/integrations/setup-mailchimp"
            component={SetupMailchimpAccount}
            hidePageMenu={true}
            isLoggedIn={
              [1, 2].includes(userData?.role) &&
              userData?.isActivated &&
              userData?.apiKey
            }
          />

          <PrivateRoute
            exact
            path="/subscription-plans"
            component={SubscriptionPlans}
            hidePageMenu={true}
            hideSidebar={true}
            isLoggedIn={
              [1, 2].includes(userData?.role) &&
              userData?.isActivated &&
              userData?.apiKey
            }
          />

          <PrivateRoute
            exact
            path="/setup-payment"
            component={PaymentForm}
            hidePageMenu={true}
            isLoggedIn={
              [1, 2].includes(userData?.role) &&
              userData?.isActivated &&
              userData?.apiKey
            }
          />

          <PrivateRoute
            exact
            path="/header-mapping"
            component={HeaderMapping}
            hidePageMenu={true}
            isLoggedIn={
              [1, 2].includes(userData?.role) &&
              userData?.isActivated &&
              userData?.apiKey
            }
          />

          <PrivateRoute
            exact
            path="/change-plan"
            component={ChangeSubscriptionPlan}
            hidePageMenu={true}
            isLoggedIn={
              [1, 2].includes(userData?.role) &&
              userData?.isActivated &&
              userData?.apiKey
            }
          />

          <PrivateRoute
            exact
            path="/resolve-conflicts"
            component={UnresolveConflictsModal}
            hidePageMenu={true}
            isLoggedIn={
              [1, 2].includes(userData?.role) &&
              userData?.isActivated &&
              userData?.apiKey
            }
          />
          {/* Admin routes  */}
          <PrivateRoute
            exact
            path="/users"
            component={Users}
            hidePageMenu={false}
            isLoggedIn={userData?.role === 1}
          />

          <PrivateRoute
            exact
            path="/pending-users"
            component={PendingUsers}
            hidePageMenu={false}
            isLoggedIn={userData?.role === 1}
          />

          <Route path="*" component={Unauthorized} />
        </Switch>
      </BrowserRouter>
      {showSnackbar && <Snackbar />}
      {loading && <Loader />}
    </Fragment>
  );
};

const mapStateToProps = (store) => {
  return {
    userDataFromRedux: store.userReducer.userData,
    warnings: store.userReducer.warnings,
    showSnackbar: store.globalReducer.showSnackbar,
    stripePublishableKey: store.billing.stripePublishableKey,
    intercomAppIdStatus: store.userReducer.intercomAppIdStatus,
    intercomAppId: store.userReducer.intercomAppId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (userData) => dispatch(userLoginSuccess(userData)),
    getIntercomAppId: () => dispatch(getIntercomAppId()),
    clearIntercomAppIdStatus: () => dispatch(clearIntercomAppIdStatus()),
    getStripePublishableKey: (formData) =>
      dispatch(getStripePublishableKey(formData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
